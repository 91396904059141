import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://vilfer.evotic.es/',
  // baseURL: 'https://testprotegeo.evotic.es/',
  baseURL: 'http://protegeo.evotic.es/',

  //baseURL: 'http://127.0.0.1:8000/',
  
  timeout: 5000,
  Accept: 'application/json', 
  withCredentials: true,
})

Vue.prototype.$http = axiosIns

export default axiosIns
