export default [ 
  {
    title: 'Tareas pendientes',
    route: 'tasks',
    icon: 'HomeIcon',
  },
    
  {
    title: 'Tareas cerradas',
    route: 'closedTasks',
    icon: 'ListIcon',
  },
    
]
